window.$ = window.jQuery = require('jquery');

(() => {
    if (!document.getElementById('applicationForm')) {
        return;
    }

    const email = document.getElementById('email');
    const emailConfirmation = document.getElementById('email_confirmation');
    let warningModal = $('#warning');

    emailConfirmation.addEventListener('paste', (e) => {
        e.preventDefault();
        e.stopPropagation();
    });

    window.addEventListener('load', function() {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        let forms = document.getElementsByClassName('needs-validation');
        // Loop over them and prevent submission
        let validation = Array.prototype.filter.call(forms, function(form) {
            form.addEventListener('submit', function(event) {

                emailConfirmation.setCustomValidity(validateEmail(email, emailConfirmation));

                form.classList.add('was-validated');

                if (form.checkValidity() === false) {
                    event.preventDefault();
                    event.stopPropagation();
                } else {
                    if (!recommendedFields() && !warningModal.hasClass('show')) {
                        event.preventDefault();
                        event.stopPropagation();
                        $('#applicantName').html($('#name').val());
                        warningModal.modal('show');
                    }

                    form.classList.remove('was-validated');
                }
            }, false);
        });
    }, false);

    function validateEmail(email, emailConfirmation) {
        return email.value === emailConfirmation.value ? '' : 'error';
    }

    function recommendedFields() {
        let recommendedSections = document.querySelectorAll('[data-recommended]');

        if (!recommendedSections.length) {
            return true;
        }

        let sectionsArr = [];

        for (let i = 0; i < recommendedSections.length; i++) {
            let el = recommendedSections[i];

            const inputs = Array.prototype.slice.apply(
                el.querySelectorAll('.form-control')
            );

            sectionsArr[i] = inputs.some(e => {
               return e.value.trim().length > 0;
            });
        }

        return sectionsArr.every(el => {
            return el !== false;
        });
    }
})();
