import 'jquery'
import 'bootstrap'

import '../../common/js/formvalidation-skilled-worker';
import '../../common/js/menu';
import '../../common/js/paneladding';
import '../../common/js/draft-form';
import '../../common/js/showfilename';
import '../../common/js/show-hidden';
import '../../common/js/disableselectbox';
import '../../common/js/collapse';
